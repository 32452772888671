import {useEffect, useState} from 'react';
import {Product, ProductRange} from '../../../interfaces/catalog-interfaces';
import {getAllItems, getItemsByCategory, getItemsByResearch} from '../../../services/catalog-services';
import styles from './items-list.module.scss';
import {I18n, useTranslation, withTranslation} from 'next-i18next';
import {connect} from 'react-redux';
import {ShopState} from '../../../interfaces/store-interfaces';
import {addToCart} from '../../../store/action';
import Loader from '../../loader/loader';
import ItemProduct from "./items/item-product";
import ItemRange from "./items/item-range";
import {config} from '../../../config-merchant';

function Items(props: {
    activeCategory: number,
    activeSorting: string,
    currentCurrency: string,
    i18n: I18n,
    nbProducts: number,
    addToCart: any,
    shoppingCart: {},
    researchQuery: string,
    setNbProducts: any,
    activeTags: number[]
}) {

    const {t} = useTranslation('common')
    const [items, setItems] = useState<(Product | ProductRange)[]>([]);
    const [page, setPage] = useState<number>(0);
    const [isLoading, setLoad] = useState<Boolean>(true);
    const [error, setError] = useState<Boolean>(false);

    const fetchItems = async () => {
        setLoad(true);
        if (props.activeCategory === 0) {
            try {
                const products = await getAllItems(props.currentCurrency, (props.i18n.language).toUpperCase(), page, props.activeSorting)
                setItems(items.concat(products['data']))
                props.setNbProducts(+products['headers']['content-range']);
            } catch (e) {
                setError(true);
            }
        } else {
            try {
                const products = await getItemsByCategory(props.currentCurrency, (props.i18n.language).toUpperCase(), page, props.activeSorting, props.activeCategory)
                setItems(items.concat(products['data']));
                props.setNbProducts(+products['headers']['content-range']);
            } catch (e) {
                setError(true);
            }
        }
        setLoad(false);
    }

    const fetchProductsOfResearchResult = async () => {
        setLoad(true);
        try {
            const products = await getItemsByResearch(props.currentCurrency, (props.i18n.language).toUpperCase(), page, props.activeSorting, props.researchQuery, props.activeCategory, props.activeTags)
            setItems(items.concat(products['data']));
            props.setNbProducts(+products['headers']['content-range']);
        } catch (e) {
            setError(true)
        }
        setLoad(false);
    }

    const reloadProducts = () => {
        window.location.reload()
    }

    useEffect(() => {
        ((props.researchQuery !== undefined && props.researchQuery !== "") || props.activeTags.length > 0) ? fetchProductsOfResearchResult() : fetchItems();
    }, [page])

    return (
        <>
            {
                error &&
                <div className={styles['error-load-product']}>
                    <p>{t('CATALOG_ERROR')}<span>☹</span></p>
                    <div className={styles['refresh']} onClick={reloadProducts}>
                        <img src="/assets/images/refresh.png" alt={"refresh"}/>
                    </div>
                </div>
            }
            <div className={styles['products-list']}>
                {
                    items.map((item) =>
                        item.hasOwnProperty("productId") ?
                            // @ts-ignore
                            (item?.productId === config.emptyBoxId ? <></> : <ItemProduct productItem={item}/>) :
                            // @ts-ignore
                            <ItemRange rangeItem={item}/>
                    )
                }
            </div>
            {
                isLoading &&
                <Loader/>
            }
            {
                ((props.nbProducts - items.length > 0) && !error && !isLoading) &&
                <>

                    {

                        (props.nbProducts - items.length !== 1) ? (
                                <div className={styles['products-list-next']}
                                     onClick={() => setPage(page + 1)}>
                                    <p dangerouslySetInnerHTML={{__html: t('CATALOG_NEXT_PRODUCTS').replace('%NBPRODUITS%', "" + (props.nbProducts - items.length)).replaceAll('%SPAN%', '<span>').replaceAll('%!SPAN%', '</span>')}}/>
                                </div>
                            ) :
                            (
                                <div className={styles['products-list-next']}
                                     onClick={() => setPage(page + 1)}>
                                    <p dangerouslySetInnerHTML={{__html: t('CATALOG_LAST_PRODUCT').replace('%SPAN%', '<span>').replaceAll('%!SPAN%', '</span>')}}/>
                                </div>
                            )
                    }


                </>
            }
        </>
    )
}

const mapState = (state: ShopState) => (
    {
        currentCurrency: state.currentCurrency,
        shoppingCart: state.shoppingCart
    }
)

const mapDispatch = (dispatch) => {
    return {
        addToCart: (product: { idP: number, quantity: number }) => dispatch(addToCart(product))
    }
}

export default connect(mapState, mapDispatch)(withTranslation('common')(Items));
